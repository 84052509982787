<template lang="pug">
v-container(fill-height)
  v-row(justify="center")
    v-col(md="6")
      v-form(v-model="loginFormValid", @submit.prevent="userLogin")
        v-card(elevation="0")
          v-card-title 
            .text-h4 {{ $t('auth.login.title') }}
          v-card-text
            .text-caption {{ $t('auth.login.desc') }}
            v-text-field(
              :label="$t('auth.form.email')",
              type="email",
              :rules="rules.email",
              v-model="email",
              prepend-icon="mdi-email"
            )
            v-text-field(
              :label="$t('auth.form.password')",
              type="password",
              :rules="rules.password",
              v-model="password",
              prepend-icon="mdi-lock"
            )
          v-card-actions
            v-btn(color="primary", text, @click="goPasswordReset") 
              v-icon(left) mdi-lock-reset
              | {{ $t('auth.btn.passwordReset') }}
            v-spacer
            v-btn(color="primary", type="submit", :disabled="!loginFormValid") 
              v-icon(left) mdi-login
              | {{ $t('auth.btn.login') }}
          v-card-title.mt-4 
            .text-h4 {{ $t('auth.register.title') }}
          v-card-text
            .text-caption {{ $t('auth.register.desc') }}
          v-card-actions
            v-spacer
            v-btn(color="primary", text, @click="goRegister") 
              v-icon(left) mdi-account-plus
              | {{ $t('auth.btn.registerNow') }}
</template>

<script>
import { mapGetters } from "vuex";
import { validateEmail } from "@/plugins/utils";
export default {
  data() {
    return {
      rules: {
        password: [(v) => !!v || this.$t("auth.rules.passwordNotEmpty")],
        email: [
          (v) => !!v || this.$t("auth.rules.emailNotEmpty"),
          (v) => !!validateEmail(v) || this.$t("auth.rules.emailNotValid"),
        ],
      },
      email: "",
      password: "",
      loginFormValid: false,
      alert: false,
    };
  },

  computed: {
    ...mapGetters({}),
  },
  methods: {
    async userLogin() {
      try {
        await this.$store.dispatch("auth/userLogin", {
          email: this.email,
          password: this.password,
        });
        this.$router.push({ name: "gameTimeline" });
      } catch (error) {
        this.$snackbar({ content: this.$t("auth.errors.loginFailed", {error}), color: "error" });
      }
    },
    goPasswordReset() {
      this.$router.push({ name: "authPasswordReset" });
    },
    goRegister() {
      this.$router.push({ name: "authRegister" });
    },
  },
};
</script>
