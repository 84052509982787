
const normalizeObjPath = (path) => {
  if (typeof path == "string")
    path = path.split(".")
  return path
}
// Gets deep property of obj expressed as array of properties []
const deepGet = (obj, path) => {
  path = normalizeObjPath(path)
  return path.reduce((o, key) => o && o[key] ? o[key] : null, obj);
}

// Map object to object 
const objectMap = (obj, fn) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  )

// Sets deep proeproty of obj, creating mid-range structures 
// if path includes number an array is created, otherwise object
// Setter can be adjusted to use Vue.set by explicity pointing to that function
const deepSet = (obj, path, value, setter = null, overwrite = true) => {
  path = normalizeObjPath(path)
  let [i, o] = [0, obj]
  for (i = 0; i < path.length - 1; i++) {
    let [p, pp] = [path[i], path[i + 1]]
    if (!o[p]) {
      let v = (typeof (pp) == 'number') ? [] : {}
      setter ? setter(o, p, v) : o[p] = v
    }
    o = o[p];
  }
  if (overwrite || o[path[i]] == undefined) {
    setter ? setter(o, path[i], value) : o[path[i]] = value
  }
  return obj
}

// Removes associated keys if objects or arrays are empty inside the level
const deepDel = (obj, path) => {
  path = normalizeObjPath(path)
  let [i, o] = [0, obj]
  for (i = 0; i < path.length - 1; i++) {
    let p = path[i]
    if (!o[p]) return obj
    o = o[p];
  }
  delete o[path[i]]
  return obj
}
// Convert value pairs { a: {text: "title A"}, b: {text: "title B"} }
// into a array form usable by v-select [{text: 'title A', value: 'a'} ...]
// text field can be choosen as second parameter
const objToValueTextList = (obj, text = "title") => {
  let ret = []
  if (!obj) return ret;
  for (let [k, v] of Object.entries(obj)) {
    let title = k
    if (typeof text === "function") {
      title = text(v)
    } else if (text) {
      title = v[text]
    }
    ret.push({ value: k, text: title, ...v })
  }
  return ret;
}


const firebaseSanitizeObj = (obj, remove = false) => {
  Object.keys(obj).forEach((k) => obj[k] == null && (remove ? delete obj[k] : obj[k] = {}));
}

const mapToItems = (obj) => {
  let ret = []
  for (let [k, v] of Object.entries(obj)) {
    ret.push({ value: k, text: v })
  }
  return ret;
}


const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export {
  deepGet, deepSet, deepDel, objToValueTextList, objectMap, mapToItems, firebaseSanitizeObj, validateEmail
}